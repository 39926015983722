var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.actualUserId,class:_vm.isCurrentUser ? 'current-user' : 'user',staticStyle:{"text-align":"center"},attrs:{"id":_vm.actualUserId},on:{"click":_vm.chatterClicked}},[_c('DialogBubble',{ref:`$bubble_${_vm.actualUserId}`,class:_vm.dialogSide,attrs:{"id":`$bubble_${_vm.actualUserId}`,"message":_vm.message}}),(!_vm.isCurrentUser && _vm.actualUserId !== 'default_avatar_character_12345')?_c('div',{staticClass:"nicknameWrapper"},[_c('div',{staticClass:"nickname"},[_vm._v(_vm._s(_vm.nickname))])]):_vm._e(),_c('v-img',{staticClass:"avatar-image",attrs:{"contain":"","id":`img-${_vm.actualUserId}`,"src":_vm.avatar}}),_c('RoundedMenu',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.isCurrentUser),expression:"!isCurrentUser"}],ref:"roundedmenu"},{
      ['privateMessage']: _vm.invitePrivate,
    })),_c('RoundedMenuCurrent',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.isCurrentUser),expression:"isCurrentUser"}],ref:"roundedmenucurrent",attrs:{"moving":_vm.mouseMoved}},{
      ['exitRoom']: _vm.leaveRoom,
      ['signOut']: () => _vm.userSignOutCall(),
      ['showAvatarList']: () => (this.showAvatarSelector = !this.showAvatarSelector),
      ['showMessages']: () => _vm.toggleMessages(),
    })),(_vm.isCurrentUser)?_c('TypeBox',{ref:`keyboard_${_vm.actualUserId}`,attrs:{"id":`keyboard_${_vm.actualUserId}`,"moving":_vm.mouseMoved}}):_vm._e(),_c('AvatarSelector',_vm._g({ref:`avatar-selector_${_vm.actualUserId}`,attrs:{"id":`avatar-selector_${_vm.actualUserId}`,"showAvatarSelector":_vm.showAvatarSelector}},{
      ['onClose']: () => {
        _vm.showAvatarSelector = false;
      },
      ['onShowLoginDialog']: () => {
        _vm.showLoginDialog = true;
      },
    })),(_vm.showLoginDialog)?_c('v-dialog',{staticClass:"pa-5 ma-5 private-dialog",attrs:{"persistent":"","scrollable":"","width":"600","min-height":"80vh"},model:{value:(_vm.showLoginDialog),callback:function ($$v) {_vm.showLoginDialog=$$v},expression:"showLoginDialog"}},[_c('LoginDialogBubble',{on:{"onCloseLoginDialog":function($event){_vm.showLoginDialog = false},"onSavedNickName":_vm.updateNickName}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }