<template>
  <v-snackbar
    v-model="model"
    :color="snackbar.type"
    :timeout="snackbar.timeout"
    right
  >
    <VIcon
      :dark="snackbar.type !== 'warning'"
      class="mr-2"
      v-text="icon"
    />
    <span
      :class="snackbar.type === 'warning' ? 'black--text' : 'white--text'"
      v-text="snackbar.msg"
    />
  </v-snackbar>
</template>

<script>
// Utilities
import {
  mapState,
} from 'vuex';

const ICON_MAP = {
  error: 'mdi-alert-octagon',
  info: 'mdi-information',
  success: 'mdi-check-circle',
  warning: 'mdi-alert-circle',
};

export default {
  data: () => ({
    model: false,
  }),

  computed: {
    ...mapState('main', ['snackbar']),
    icon() {
      return ICON_MAP[this.snackbar.type] || 'mdi-playlist-check';
    },
  },

  watch: {
    snackbar() {
      this.model = true;
    },
  },
};
</script>
