var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","height":"200","z-index":"1000"}},[_c('v-btn',{directives:[{name:"touch",rawName:"v-touch",value:({
      start: () => (_vm.movingTouch = false),
      end: () => _vm.toggleMenuTouch,
      left: () => (_vm.movingTouch = true),
      down: () => (_vm.movingTouch = true),
      right: () => (_vm.movingTouch = true),
      up: () => (_vm.movingTouch = true),
      move: () => (_vm.movingTouch = true),
    }),expression:"{\n      start: () => (movingTouch = false),\n      end: () => toggleMenuTouch,\n      left: () => (movingTouch = true),\n      down: () => (movingTouch = true),\n      right: () => (movingTouch = true),\n      up: () => (movingTouch = true),\n      move: () => (movingTouch = true),\n    }"}],staticClass:"mx-2 menu-activator",attrs:{"height":"200","dark":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}}),_c('v-btn',{staticClass:"mx-2 menu-item",class:_vm.hideMenu ? 'hidden' : 'nothidden',attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.emit('showAvatarList')}},nativeOn:{"touchstart":function($event){$event.preventDefault();return _vm.emit('showAvatarList')}}},[_c('div',[_c('v-icon',[_vm._v(" mdi mdi-account-switch ")])],1),_c('div',{staticClass:"icon-caption"},[_vm._v("Switch Avatar")])]),_c('v-btn',{staticClass:"mx-2 menu-item",class:_vm.hideMenu ? 'hidden' : 'nothidden',attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.emit('privateMessage')}},nativeOn:{"touchstart":function($event){$event.preventDefault();return _vm.emit('privateMessage')}}},[_c('div',[_c('v-icon',[_vm._v(" mdi-account-cog")])],1),_c('div',{staticClass:"icon-caption"},[_vm._v("Profile")])]),_c('v-btn',{staticClass:"mx-2 menu-item",class:_vm.hideMenu ? 'hidden' : 'nothidden',attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.emit('exitRoom')}},nativeOn:{"touchstart":function($event){$event.preventDefault();return _vm.emit('exitRoom')}}},[_c('div',[_c('v-icon',[_vm._v(" mdi-door-open ")])],1),_c('div',{staticClass:"icon-caption"},[_vm._v("Exit Room")])]),_c('v-btn',{directives:[{name:"touch",rawName:"v-touch",value:({
      end: () => _vm.toggleMenu,
    }),expression:"{\n      end: () => toggleMenu,\n    }"}],staticClass:"mx-2 menu-item",class:_vm.hideMenu ? 'hidden' : 'nothidden',attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}},nativeOn:{"touchstart":function($event){$event.preventDefault();return _vm.toggleMenu.apply(null, arguments)}}},[_c('div',[_c('v-icon',[_vm._v(" mdi mdi-eye-off ")])],1),_c('div',{staticClass:"icon-caption"},[_vm._v("Hide")])]),_c('v-btn',{directives:[{name:"touch",rawName:"v-touch",value:({
      end: () => _vm.toggleMenu,
    }),expression:"{\n      end: () => toggleMenu,\n    }"}],staticClass:"mx-2 menu-item",class:_vm.hideMenu ? 'hidden' : 'nothidden',attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.emit('signOut')}},nativeOn:{"touchstart":function($event){$event.preventDefault();return _vm.emit('signOut')}}},[_c('div',[_c('v-icon',{attrs:{"disabled":_vm.getCurrentUser.isAnonymous}},[_vm._v(" mdi mdi-logout-variant ")])],1),_c('div',{staticClass:"icon-caption",attrs:{"disabled":_vm.getCurrentUser.isAnonymous}},[_vm._v("Logout")])]),_c('v-btn',{staticClass:"mx-2 menu-item",class:_vm.hideMenu ? 'hidden' : 'nothidden',attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.emit('showMessages')}},nativeOn:{"touchstart":function($event){$event.preventDefault();return _vm.emit('showMessages')}}},[_c('div',[_c('v-icon',[_vm._v(" mdi-timeline-text-outline ")])],1),_c('div',{staticClass:"icon-caption"},[_vm._v("Messages")])]),_c('v-btn',{directives:[{name:"touch",rawName:"v-touch",value:({
      start: () => (_vm.movingTouch = false),
      end: () => _vm.toggleMenuTouch,
      left: () => (_vm.movingTouch = true),
      down: () => (_vm.movingTouch = true),
      right: () => (_vm.movingTouch = true),
      up: () => (_vm.movingTouch = true),
      move: () => (_vm.movingTouch = true),
    }),expression:"{\n      start: () => (movingTouch = false),\n      end: () => toggleMenuTouch,\n      left: () => (movingTouch = true),\n      down: () => (movingTouch = true),\n      right: () => (movingTouch = true),\n      up: () => (movingTouch = true),\n      move: () => (movingTouch = true),\n    }"}],staticClass:"mx-2 menu-item",class:'oculted',attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}},[_c('div'),_c('div',{staticClass:"icon-caption"},[_vm._v("toggle")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }