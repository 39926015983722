import { render, staticRenderFns } from "./PrivateDialogBubble.vue?vue&type=template&id=20f1cfb2&scoped=true"
import script from "./PrivateDialogBubble.vue?vue&type=script&lang=js"
export * from "./PrivateDialogBubble.vue?vue&type=script&lang=js"
import style0 from "./PrivateDialogBubble.vue?vue&type=style&index=0&id=20f1cfb2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f1cfb2",
  null
  
)

export default component.exports